import React from "react";
import PI from "../images/popicon.svg"
import "../css/footer.css"

function Footer(){
    return(
        <div className="footerContainer">
            <footer>
                <a href="https://quadqode.com" target="#"><img src={PI}/></a>
            </footer>
        </div>
    );
}

export default Footer;