import React from "react";
import "../css/header.css";

function Header() {
  return (
    <div className="headContainer">
      <header>community.quadqode</header>
      <a href="https://quadqode.com/discord" target="#">
        join us
      </a>
    </div>
  );
}

export default Header;
