import React from "react";
import "../css/home.css";

function Home() {
  return (
    <div className="homeContainer">
        <div className="homeDiv">
        <header>CP Docs</header>
      <div className="homeContent">
        <h1>Sites for Competitive Programming:</h1>
        <ul>
          <li>
            <a target="#" href="https://codeforces.com/">Codeforces</a> - One of the best site for Competitive Programming. They
            host a lot of contests, have some really great blogs for CP, and
            6000+ problems based on ratings from newbie to Legendary
            Grandmaster.
          </li>
          <li>
          <a target="#" href="https://codechef.com/">CodeChef</a> - Another great site for competitive programming. A lot of
            Indian Institutes like IITs, NITs and other private colleges host
            their contest on this website. Their long Contest are really good
            for learning new algorithms and Concepts.
          </li>
          <li>
          <a target="#" href="https://atcoder.jp/">AtCoder</a> - A Japanese site for competitive programming. A lot of
            Japanese companies like Panasonic host their contest on this site
            for hiring.
          </li>
          <li><a target="#" href="https://hackerrank.com/">HackerRank</a> - Great platform for beginners.</li>
          <li>
          <a target="#" href="https://hackerearth.com/">HackerEarth</a> - This site hosts a lot of hackathons and many
            recruiters take their recruiting test on this platform.
          </li>
        </ul>
      </div>
      <div className="homeContent">
        <h1>Sites for learning:</h1>
        <ul>
          <li>
          <a target="#" href="https://cp-algorithms.com/">cp-algorithms</a> - A really great website for competitive programmers.
            Contains a wide variety of topics.
          </li>
          <li>
          <a target="#" href="https://geeksforgeeks.org/">geeks for geeks</a> - All in one platform for coding. Contains a lot of
            articles, Problems, Interview experiences etc.
          </li>
          <li>
          <a target="#" href="https://www.cplusplus.com/reference/vector/vector/">cplusplus</a> - A really good site for a complete documentation of
            Standard template library in C++.
          </li>
          <li>
          <a target="#" href="https://cses.fi/problemset">cses</a> - This site has limited but some great problems on concepts
            like trees, dynamic programming, Graphs etc. from beginner to
            advanced level.
          </li>
          <li>
          <a target="#" href="https://leetcode.com/">Leetcode</a> - This is also one of really good site but great for
            interview preprations.
          </li>
          <li>
          <a target="#" href="https://interviewbit.com/">InterviewBit</a> - Again a great site with limited problems on a lot of
            topics but very important for interview preprations.
          </li>
        </ul>
      </div>
      <div className="homeContent">
        <h1>Youtube Channels:</h1>
        <ul>
          <li><a target="#" href="https://www.youtube.com/c/Errichto">Errichto</a> - <a className="listLinks" href="https://codeforces.com/profile/Errichto" target="#">Legendary grandmaster</a> on Codeforces.</li>
          <li><a target="#" href="https://www.youtube.com/c/SecondThread/featured">SecondThread</a> - <a className="listLinks" href="https://codeforces.com/profile/SecondThread" target="#">International Grandmaster</a> on Codeforces.</li>
          <li><a target="#" href="https://www.youtube.com/c/ColinGalen/featured">Colin Galen</a> - <a className="listLinks" href="https://codeforces.com/profile/galen_colin" target="#">International Grandmaster</a> on Codeforces.</li>
          <li><a target="#" href="https://www.youtube.com/c/WilliamLin168/featured">WilliamLin168</a> - <a className="listLinks" href="https://codeforces.com/profile/tmwilliamlin168" target="#">International Grandmaster</a> on Codeforces.</li>
          <li>
          <a target="#" href="https://www.youtube.com/user/MrHulasingh25">Kartik arora</a> - I love his DP playlist. <a className="listLinks" href="https://codeforces.com/profile/kartik8800" target="#">Candidate Master</a> on
            Codeforces.
          </li>
          <li>
          <a target="#" href="https://www.youtube.com/channel/UC0zvY3yIBQTrSutsV-4yscQ/featured">Code n code</a> - This channel have some nice playlists on number theory
            and graph theory.
          </li>
          <li><a target="#" href="https://www.youtube.com/c/CSDojo/featured">CSDojo</a></li>
          <li>
          <a target="#" href="https://www.youtube.com/c/Freecodecamp">FreeCodeCamp</a> - There are a lot of great videos on this channel but I
            will suggest you some of really important videos on this channel
            like: <a className="listLinks" target="#" href="https://www.youtube.com/watch?v=zuegQmMdy8M">Pointers in C++</a>, <a className="listLinks" target="#" href="https://www.youtube.com/watch?v=RBSGKlAvoiM">Data Structures easy to advanced</a>, <a className="listLinks" target="#" href="https://www.youtube.com/watch?v=09_LlHjoEiY">Algorithm Course</a>.
          </li>
        </ul>
      </div>

        </div>
      </div>
  );
}

export default Home;
