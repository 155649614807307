import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route } from "react-router-dom";

import Home from "./pages/home";
import Header from "./components/header";
import Footer from "./components/footer";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header/>
        <Route exact path="/" component={Home} />
        <Route exact path="/docs/cp" component={Home} />
        <Footer/>
      </div>
    </BrowserRouter>
  );
}

export default App;
